.failure-error-image-wrapper {
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  //Using image from cloudfront asset as fetching asset from ESS URL does not work.
  background-size: 100% 100%;
}

.failure-error-image{
  width: 31rem;
  height: 19rem;
}
